import React from 'react';
import Subsection from './Subsection';
import { PropertyGroup } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_ARRANGEMENTS_KEY } from '../../util/types';

import config from '../../config';

const SubsectionArrangement = props => {
  const { arrangements } = props;

  const arrangementsOptions = findOptionsForSelectFilter(
    LISTING_ARRANGEMENTS_KEY,
    config.custom.filters
  );

  return (
    <Subsection titleId="ListingPage.arrangement">
      <PropertyGroup
        id="arrangement"
        options={arrangementsOptions}
        selectedOptions={arrangements}
        twoColumns={true}
      />
    </Subsection>
  );
};

export default SubsectionArrangement;
