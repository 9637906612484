import React from 'react';
import Subsection from './Subsection';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { getSpotifyId } from '../../util/urlHelpers';

import css from './ListingPage.module.css';

const SpotifyEmbed = props => {
  const { link } = props;

  const spotifyId = getSpotifyId(link);
  if (!spotifyId) {
    const errorClasses = classNames(css.embedError, css.spotifyEmbed);
    return (
      <div className={errorClasses}>
        <FormattedMessage id="ListingPage.invalidLinkError" />
        <span>{link}</span>
      </div>
    );
  }

  return (
    <iframe
      className={css.spotifyEmbed}
      src={`https://open.spotify.com/embed${spotifyId}?utm_source=generator&theme=0`}
      frameBorder="0"
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
      allowFullScreen
    />
  );
};

const SubsectionSpotifyMaybe = props => {
  const { spotifyLinks: links } = props;
  if (!links || links.length === 0) {
    return null;
  }

  return (
    <div titleId="ListingPage.spotify">
      <h3>
        <FormattedMessage id="ListingPage.spotify" />
      </h3>
      <div className={css.spotifyContainer}>
        {links.map(link => (
          <SpotifyEmbed key={link} link={link} />
        ))}
      </div>
    </div>
  );
};

export default SubsectionSpotifyMaybe;
