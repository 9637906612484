import React from 'react';
import Product from './Product';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css';

const SectionProducts = props => {
  const { productListings, onManageDisableScrolling, intl } = props;

  return (
    <div className={css.sectionProducts}>
      <h2 className={css.productsTitle}>
        <FormattedMessage id="ListingPage.products" />
      </h2>

      {productListings.map(listing => (
        <Product
          key={listing.id.uuid}
          listing={listing}
          onManageDisableScrolling={onManageDisableScrolling}
          intl={intl}
        />
      ))}
    </div>
  );
};

export default SectionProducts;
