import React from 'react';
import Subsection from './Subsection';
import { PropertyGroup } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_PERFORMING_LOCATIONS_KEY } from '../../util/types';
import config from '../../config';

const SubsectionLocation = props => {
  const { locations } = props;

  const locationsOptions = findOptionsForSelectFilter(
    LISTING_PERFORMING_LOCATIONS_KEY,
    config.custom.filters
  );

  return (
    <Subsection titleId="ListingPage.location">
      <PropertyGroup
        id="location"
        options={locationsOptions}
        selectedOptions={locations}
        twoColumns={true}
      />
    </Subsection>
  );
};

export default SubsectionLocation;
