import React from 'react';
import Subsection from './Subsection';
import { FormattedMessage } from 'react-intl';
import { getYoutubeVideoId } from '../../util/urlHelpers';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
SwiperCore.use([Pagination, Navigation]);

import css from './ListingPage.module.css';

// Copy directly from Swiper library
import './swiper/swiper.min.css';
import './swiper/pagination.min.css';
import './swiper/navigation.min.css';
// Custom css for Swiper
import './customSwiper.css';

const YoutubeEmbed = props => {
  const { link } = props;

  const videoId = getYoutubeVideoId(link);
  if (!videoId) {
    return (
      <div className={css.embedError}>
        <FormattedMessage id="ListingPage.invalidLinkError" />
        <span>{link}</span>
      </div>
    );
  }

  return (
    <iframe
      className={css.youtubeEmbed}
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

const SubsectionVideoMaybe = props => {
  const { videoLinks } = props;
  if (!videoLinks || videoLinks.length === 0) {
    return null;
  }

  return (
    <div titleId="ListingPage.video">
      <h3>
        <FormattedMessage id="ListingPage.video" />
      </h3>
      <Swiper
        className={css.swiper}
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
      >
        {videoLinks.map(videoLink => (
          <SwiperSlide key={videoLink}>
            <YoutubeEmbed link={videoLink} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SubsectionVideoMaybe;
