import React from 'react';
import { AvatarLarge, AvatarMedium } from '../../components';
import { getProfileImage } from '../../util/helperFunc';

import css from './ListingPage.module.css';

const getUserWithUpdatedProfileImage = (user, listing) => {
  const { profileImageId } = listing.attributes.publicData;
  const profileImage = getProfileImage(listing.images, profileImageId);

  return {
    ...user,
    profileImage,
  };
};

const SectionAvatar = props => {
  const { user: rawUser, listing } = props;
  const user = getUserWithUpdatedProfileImage(rawUser, listing);

  return (
    <div className={css.sectionAvatar}>
      <AvatarLarge
        user={user}
        className={css.avatarDesktop}
        initialsClassName={css.initialsDesktop}
        disableProfileLink
      />

      <AvatarMedium user={user} className={css.avatarMobile} disableProfileLink />
    </div>
  );
};

export default SectionAvatar;
