import React from 'react';
import Subsection from './Subsection';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { getSoundcloudId } from '../../util/urlHelpers';

import css from './ListingPage.module.css';

const SoundcloudEmbed = props => {
  const { link } = props;

  const soundcloudId = getSoundcloudId(link);
  if (!soundcloudId) {
    const errorClasses = classNames(css.embedError, css.soundcloudEmbed);
    return (
      <div className={errorClasses}>
        <FormattedMessage id="ListingPage.invalidLinkError" />
        <span>{link}</span>
      </div>
    );
  }

  return (
    <iframe
      className={css.soundcloudEmbed}
      src={`https://w.soundcloud.com/player/?url=https%3A//soundcloud.com${soundcloudId}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=flase`}
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
    />
  );
};

const SubsectionSoundcloudMaybe = props => {
  const { soundcloudLinks: links } = props;
  if (!links || links.length === 0) {
    return null;
  }

  return (
    <div titleId="ListingPage.soundcloud">
      <h3>
        <FormattedMessage id="ListingPage.soundcloud" />
      </h3>
      <div className={css.soundcloudContainer}>
        {links.map(link => (
          <SoundcloudEmbed key={link} link={link} />
        ))}
      </div>
    </div>
  );
};

export default SubsectionSoundcloudMaybe;
