import React, { useState } from 'react';
import ProductImage from './ProductImage';
import Subsection from './Subsection';
import { FormattedMessage } from 'react-intl';
import { priceData } from '../../util/currency';
import { getOptionsText } from '../../util/helperFunc';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_GENRES_KEY, LISTING_TALENTS_KEY, LISTING_VIBES_KEY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.module.css';

const LineItem = props => {
  const { labelId, value } = props;

  return (
    <div className={css.lineItem}>
      <span className={css.lineItemLabel}>
        <FormattedMessage id={labelId} />
      </span>
      <span className={css.lineItemValue}>{value}</span>
    </div>
  );
};

const Product = props => {
  const [isExpand, setIsExpand] = useState(false);
  const { listing, onManageDisableScrolling, intl } = props;
  const { title, description, price, publicData } = listing.attributes;
  const { talents, timeframe, vibes, genres, technicalRider, hospitalityRider } = publicData;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const { filters: filterConfig } = config.custom;
  const talentsOptions = findOptionsForSelectFilter(LISTING_TALENTS_KEY, filterConfig);
  const genresOptions = findOptionsForSelectFilter(LISTING_GENRES_KEY, filterConfig);
  const vibesOptions = findOptionsForSelectFilter(LISTING_VIBES_KEY, filterConfig);

  const talentsText = getOptionsText(talentsOptions, talents);
  const genresText = getOptionsText(genresOptions, genres);
  const vibesText = getOptionsText(vibesOptions, vibes);

  const showDetailsMessageId = isExpand ? 'ListingPage.hideDetails' : 'ListingPage.showDetails';

  const productBodyClass = isExpand ? css.productBody : css.displayNone;

  const toggle = () => {
    setIsExpand(!isExpand);
  };

  return (
    <div className={css.product}>
      <div className={css.productHeader}>
        <ProductImage listing={listing} onManageDisableScrolling={onManageDisableScrolling} />

        <div className={css.productInfo}>
          <div className={css.productTitleWrapper}>
            <div className={css.productTitle}>{title}</div>
            <div className={css.productPrice} title={priceTitle}>
              {formattedPrice}
            </div>
          </div>
          <div className={css.productTalents}>{talentsText}</div>
          <LineItem labelId="ListingPage.productTimeframe" value={timeframe} />
          <LineItem labelId="ListingPage.productVibes" value={vibesText} />
          <LineItem labelId="ListingPage.productGenres" value={genresText} />
          <div className={css.showDetails} onClick={toggle}>
            <FormattedMessage id={showDetailsMessageId} />
          </div>
        </div>
      </div>

      <div className={productBodyClass}>
        <div className={css.productDescription}>{description}</div>
        {technicalRider ? (
          <Subsection titleId="ListingPage.technicalRider">{technicalRider}</Subsection>
        ) : null}

        {hospitalityRider ? (
          <Subsection titleId="ListingPage.hospitalityRider">{hospitalityRider}</Subsection>
        ) : null}
      </div>
    </div>
  );
};

export default Product;
