import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconMapsPin, IconMessageBubble, InlineTextButton } from '../../components';
import { getOptionsText } from '../../util/helperFunc';
import config from '../../config';

import css from './ListingPage.module.css';

const Tag = props => {
  const { label } = props;
  return <div className={css.tag}>{label}</div>;
};

const getGenreTags = (genresOptions, keys) => {
  const genres = genresOptions.filter(genre => keys.includes(genre.key));
  return genres.map(genre => {
    const { key, label } = genre;
    return <Tag key={key} label={label} />;
  });
};

const getCityState = async (latitude, longitude) => {
  const accessToken = config.maps.mapboxAccessToken;
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      const city = data.features[0].context.find(c => c.id.startsWith('place')).text;
      return { city };
    } else {
      throw new Error('No address found');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const SectionHeading = props => {
  const {
    richTitle,
    listingTalents,
    listingGenres,
    talentsOptions,
    genresOptions,
    showContactUser,
    onContactUser,
    geolocation,
  } = props;

  const [city, setCity] = useState('');

  useEffect(() => {
    const { lat, lng } = geolocation || {};
    const location = lat && lng ? `${lat}, ${lng}` : '';

    getCityState(lat, lng)
      .then(result => {
        const { city } = result || {};
        setCity(city || '');
      })
      .catch(error => console.error(error));
  }, [geolocation]);

  const talentsInfo = getOptionsText(talentsOptions, listingTalents);
  const genreTags = getGenreTags(genresOptions, listingGenres);
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <div className={css.author}>
          <h1 className={css.title}>{richTitle}</h1>
          <h3 className={css.city}>
            {' '}
            {city}
            <IconMapsPin />
          </h3>
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
                <IconMessageBubble />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
        <p className={css.subtitle}>{talentsInfo}</p>
        <div className={css.genresContainer}>{genreTags}</div>
      </div>
    </div>
  );
};

export default SectionHeading;
