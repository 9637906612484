import React, { createRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconArrowHead } from '../../components';

import css from './ListingPage.module.css';

const ANIMATION_DURATION = 200;

const Subsection = props => {
  const { titleId, children } = props;
  const [isExpand, setIsExpand] = useState(false);
  const iconRef = createRef();

  const toggle = () => {
    iconRef.current
      .animate(
        {
          transform: ['rotate(0deg)', 'rotate(180deg)'],
        },
        {
          duration: ANIMATION_DURATION,
        }
      )
      .finished.then(() => {
        setIsExpand(!isExpand);
      });
  };

  const arrowDirection = isExpand ? 'up' : 'down';
  return (
    <div className={css.subsection}>
      <div className={css.subsectionHeader}>
        <div className={css.subsectionTitle} onClick={toggle}>
          <FormattedMessage id={titleId} />
        </div>
        <div className={css.subsectionIcon} onClick={toggle} ref={iconRef}>
          <IconArrowHead direction={arrowDirection} />
        </div>
      </div>

      {isExpand && <div className={css.subsectionContent}>{children}</div>}
    </div>
  );
};

export default Subsection;
