import React, { useState } from 'react';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';

import css from './ListingPage.module.css';

const ProductImage = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { listing, onManageDisableScrolling } = props;

  const { attributes, images = [] } = listing;
  const { title } = attributes;

  const hasImages = images.length > 0;
  const firstImage = hasImages ? images[0] : null;

  const setModalState = isOpen => () => {
    setIsModalOpen(isOpen);
  };

  return (
    <div className={css.productImage}>
      <div className={css.productImageWrapper} onClick={setModalState(true)}>
        <ResponsiveImage
          rootClassName={css.productImageContent}
          alt={title}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x', 'landscape-crop4x', 'landscape-crop6x']}
        />
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={isModalOpen}
        onClose={setModalState(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={images}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default ProductImage;
